<script lang="ts" setup>
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import useValidation from '@/composables/useValidation'

const props = defineProps<{
  loading: boolean
}>()

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'action', data: Dialogs['document/DialogDelegate']['contextData']): void
}>()

const { t } = useI18n()

const data = reactive({
  message: '',
  messageMaxLength: 1000,
})

const email = ref('')

const { isEmail } = useValidation()

const delegate = () => {
  emit('action', { email: email.value, message: data.message })
}

const isValidInput = (input: string) => {
  return isEmail(input)
}

const disabled = computed(() => {
  return !isValidInput(email.value) || props.loading
})
</script>

<template>
  <base-dialog
    data-cy="assignDialog"
    :title="t('delegate.dialog.title')"
    :disabled="disabled"
    :loading="loading"
    :action-text="t('delegate.delegate')"
    @action="delegate"
    @close="emit('close')"
  >
    <div class="pb-4">
      {{ $t('delegate.dialog.text') }}
    </div>
    <div class="mb-2">
      <strong>{{ $t('delegate.dialog.email.title') }}</strong>
    </div>
    <div>
      <v-text-field
        v-model="email"
        variant="filled"
        :label="$t('global.email_address')"
        :rules="[value => isValidInput(value)]"
        type="email"
        autocomplete="email"
        data-cy="assignSignerEmail"
      />
    </div>
    <div class="mb-2">
      <strong>{{ $t('delegate.dialog.message.title') }}</strong>
    </div>
    <v-textarea
      v-model="data.message"
      auto-grow
      counter
      :maxlength="data.messageMaxLength"
      clearable
      clear-icon="custom:clear"
      :label="$t('delegate.dialog.message.placeholder')"
      data-cy="assignSignerMessage"
      @blur="data.message = data.message.trim()"
    />
    <div class="d-flex text-caption">
      <!-- TODO: replace with icon element? -->
      <nuxt-img width="24" height="24" class="mt-1 mr-4" src="/img/info-warning.svg" alt="" />
      <strong>{{ $t('delegate.dialog.important') }}</strong>
    </div>
  </base-dialog>
</template>
